import { login, logout, currentUser } from "@/features/account/services/accountService";
import { goTo, currentRouteIsPublic } from "@/services/routeService";
import { handleError } from "@/services/errorUtility";
import { createCompletion } from "@/services/completion";
import { pascalToCamelCase } from "@/services/stringUtility";

function getDefaultState() {
    return {
        user: null,
        policies: null,
        isLoggedIn: false,
        isLoaded: false,
        loggingIn: false,
        loadCompletion: createCompletion()
    }
}

function setFlatSettings(user, userSettings) {
    userSettings.forEach(setting => {
        const settingName = pascalToCamelCase(setting.settingType);
        user[settingName] = setting.isEnabled;
    });
}

const state = getDefaultState();

const getters = {
    personId: (state) => {
        return state.user?.personId;
    },
    hasPolicy: state => policy => {
        return state.policies?.has(policy) ?? false;
    }
}

const actions = {
    async load({ commit, dispatch }) {
        try {
            await dispatch("refreshUser");
        }
        finally {
            commit("loaded");
            state.loadCompletion.complete();
        }
    },
    async loadCompletion({ state }) {
        await state.loadCompletion();
    },
    async login({ commit, dispatch }, { emailAddress, password }) {
        try {
            commit("loggingIn", true);
            await login(emailAddress, password);
            await dispatch("refreshUser");

            goTo("/");
        }
        catch (error) {
            commit("clear", error);
            handleError(error);
        }
        finally {
            commit("loggingIn", false);
        }
    },

    async logout({ commit }) {
        await logout();
        commit("clear");

        if(!currentRouteIsPublic()) {
            goTo("/login");
        }
    },
    async refreshUser({ commit }) {
        const user = await currentUser();
        if(user !== null) {
            commit("user", user);
        }
    },
    setSettings({ commit, dispatch }, settings) {
        commit("settings", settings);
        dispatch("localisation/load", null, { root: true });
    },
};

const mutations = {
    user(state, user) {
        state.user = user;
        state.policies = new Set(user.policies);
        state.isLoggedIn = true;
        setFlatSettings(state.user, user.userSettings);
    },
    settings(state, settings) {
        if(state.user) {
            setFlatSettings(settings, settings.userSettings)
            Object.assign(state.user, settings);
        }
    },
    loaded(state) {
        state.isLoaded = true;
    },
    loggingIn(state, loggingIn) {
        state.loggingIn = loggingIn;
    },
    clear(state) {
        state.user = null;
        state.isLoggedIn = false;
        state.loggingIn = false;
    }
};

export const authentication = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
